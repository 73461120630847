<template>
  <div>
    <h2 :class="notFoundHide" class="destinations__title">{{ getLocaleMsg('title') }}</h2>
    <swiper :options="swiperOptions" class="destinations__block">
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('IST')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="istanbulImage" alt="Istanbul image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.istanbul') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('DXB')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="dubaiImage" alt="Dubai image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.dubai') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('BER')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="berlinImage" alt="Berlin Image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.berlin') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('MOW')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="mowImage" alt="Moscow image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.moscow') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('WAW')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="warsawImage" alt="Warsaw image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.warsaw') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('SFO')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="franciscoImage" alt="Francisco image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.sanFrancisco') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('IST')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="istanbulImage" alt="Istanbul image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.istanbul') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('DXB')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="dubaiImage" alt="Dubai image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.dubai') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('BER')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="berlinImage" alt="Berlin image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.berlin') }}</p>
        </div>
        <!-- p -->
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('MOW')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="mowImage" alt="Moscow image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.moscow') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('WAW')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="warsawImage" alt="Moscow image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.warsaw') }}</p>
        </div>
      </swiper-slide>
      <swiper-slide class="flex cursor-pointer flex-col items-center text-[#1B2226]">
        <div @click="submitForm('SFO')">
          <div class="destinations__block-img" :style="{ background: notFoundBackground }">
            <div class="rounded-[50%] border-4 bg-white" :class="notFoundBorder">
              <img v-lazy="franciscoImage" alt="Francisco image" />
            </div>
          </div>
          <p :class="notFound" class="destinations__block-title">{{ getLocaleMsg('cities.sanFrancisco') }}</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import ApiUtil from '@/utils/api';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import istanbulImage from '@/assets/images/istanbul-stories.webp';
import dubaiImage from '@/assets/images/dubay.webp';
import berlinImage from '@/assets/images/berlin.webp';
import mowImage from '@/assets/images/moskva.webp';
import warsawImage from '@/assets/images/varshava.webp';
import franciscoImage from '@/assets/images/san-fransisko.webp';

export default {
  name: 'PopularDestination',
  data() {
    return {
      swiperOptions: {
        slidesPerView: 7,
        spaceBetween: 32,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        preloadImages: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          1279: {
            spaceBetween: 10,
          },
          639: {
            spaceBetween: 20,
            slidesPerView: 5,
          },
          230: {
            spaceBetween: 10,
            slidesPerView: 3,
          },
        },
      },
      formData: {
        fields: [
          {
            departure: {
              name: null,
              code: null,
            },
            arrival: {
              name: null,
              code: null,
            },
            dates: {
              one: '',
              two: '',
            },
          },
        ],
        passengers: {
          tripClass: 'E',
          adultsQty: 1,
          childrensQty: 0,
          infantsQty: 0,
        },
        flightOneWay: false,
        flightIsExtended: false,
        flightDatesFlexible: false,
        flightDirectOnly: false,
      },
      istanbulImage: istanbulImage,
      dubaiImage: dubaiImage,
      berlinImage: berlinImage,
      mowImage: mowImage,
      warsawImage: warsawImage,
      franciscoImage: franciscoImage,
    };
  },
  computed: {
    notFound() {
      if (this.$route.name === 'notFound') {
        return 'text-white';
      }
      return '';
    },
    notFoundBackground() {
      if (this.$route.name === 'notFound') {
        return 'rgba(255, 255, 255, 0.26)';
      }
      return '';
    },
    notFoundHide() {
      if (this.$route.name === 'notFound') {
        return 'hidden';
      }
      return '';
    },
    notFoundBorder() {
      if (this.$route.name === 'notFound') {
        return 'border-[#1D6BC0]';
      }
      return 'border-transparent';
    },
  },
  methods: {
    ...mapActions(['getTickets']),
    ...mapMutations(['setTicketsSearchStatus']),
    getFormFormattedData(arrival) {
      const formattedData = {
        directions: [],
      };
      var todayDate = new Date();
      todayDate.setDate(todayDate.getDate() + 1);
      var year = todayDate.getFullYear();
      var month = String(todayDate.getMonth() + 1).padStart(2, '0');
      var day = String(todayDate.getDate()).padStart(2, '0');

      var formattedDate = year + '-' + month + '-' + day;

      this.formData.fields.forEach((field) => {
        const direction = {
          departure_code: 'TAS',
          arrival_code: arrival,
          date: formattedDate,
        };
        formattedData.directions.push(direction);
      });
      formattedData.count_adult = 1;
      formattedData.count_child = 0;
      formattedData.count_infant = 0;
      formattedData.class_type = 'E';
      formattedData.direct = false;
      formattedData.flexible = true;
      return formattedData;
    },
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`);
    },
    async submitForm(value) {
      await this.setTicketsSearchStatus('start');
      await ApiUtil.post('/air/search/init', this.getFormFormattedData(value)).then((response) => {
        localStorage.setItem('request_guid', response.data.request_guid);
      });

      this.getTickets({
        searchParams: this.getFormFormattedData(value),
        formData: this.formData,
      });
    },
  },
};
</script>
<style></style>
