<template>
  <div>
    <Transition
      enter-active-class="slide-bottom-enter-active"
      leave-active-class="slide-bottom-leave-active"
      enter-class="slide-bottom-enter"
      leave-to-class="slide-bottom-leave-to"
    >
      <MobileModal v-if="isMobileModal && windowScreen" class="sm:hidden" @closeEvent="mobileModalEvent" />
    </Transition>
    <div class="section section_home_tickets-search-widget pb-10">
      <div class="section__container grid-container mx-auto mt-[24px] w-full md:mt-[36px] lg:mt-[43px] lg:max-w-7xl">
        <h1 class="text-center text-2xl font-bold text-white max-sm:text-xl">
          {{ getLocaleMsg('we_show') }}
        </h1>
        <div class="mt-4 flex justify-center">
          <p class="text-center text-xl font-medium text-white max-sm:max-w-[230px] max-sm:text-xs">
            {{ getLocaleMsg('ideal') }}
          </p>
        </div>
        <div class="my-8 flex justify-center space-x-2">
          <div
            class="header__tour-btn flex cursor-pointer items-center space-x-2 rounded-xl bg-white px-4 py-2 text-sm font-bold text-[#36454F]"
          >
            <img v-lazy="newAirplane" alt="" />
            <span>{{ getLocaleMsg('flight') }}</span>
          </div>
          <button
            class="header__tour-btn flex cursor-pointer items-center space-x-2 rounded-xl bg-white/[8%] px-4 py-2 text-sm font-bold text-white"
            @click="featureComingSoon"
          >
            <img v-lazy="bedImage" alt="" />
            <span>{{ getLocaleMsg('hotel') }}</span>
          </button>
          <router-link
            :to="{ name: 'insurance' }"
            class="header__tour-btn flex cursor-pointer items-center space-x-2 rounded-xl bg-white/[8%] px-4 py-2 text-sm font-bold text-white"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <g clip-path="url(#uicons-nr8aa9peeb)">
                <path
                  d="M10.5 13H8V10H10.5V7.5H13.5V10H16V13H13.5V15.5H10.5V13ZM12 2L4 5V11.09C4 16.14 7.41 20.85 12 22C16.59 20.85 20 16.14 20 11.09V5L12 2Z"
                  fill="currentColor"
                ></path>
              </g>
              <defs>
                <clipPath id="uicons-nr8aa9peeb"><rect width="24" height="24" fill="currentColor"></rect></clipPath>
              </defs>
            </svg>
            <span>Insurance</span>
          </router-link>
        </div>
        <div class="relative">
          <TicketsSearchWidget v-show="activeTab === 'tickets'" />
          <NemoWidget v-show="activeTab === 'charters'" />
        </div>
      </div>
      <div class="absolute bottom-0 right-[3%]">
        <img v-lazy="cloudImage" alt="Cloud image" />
      </div>
    </div>
    <div class="section destinations">
      <div class="section__container grid-container">
        <PopularDestinations />
      </div>
    </div>
    <div class="section flights">
      <div class="section__container grid-container">
        <Flights :flights="flights" />
      </div>
    </div>
    <!-- <div class="section bg-white">
      <div class="section__container grid-container">
        <Hotels />
      </div>
    </div> -->
    <InsuranceSection />
    <div class="section our-advantage">
      <div class="section__container grid-container">
        <OurAdvantage />
      </div>
    </div>
    <div class="mobile__alltrip">
      <AlltripMobile />
    </div>
  </div>
</template>

<script>
import TicketsSearchWidget from '@/components/Tickets/SearchWidget/Widget';
import NemoWidget from '@/components/Tickets/NemoWidget';
import Flights from '@/components/Home/Flights';
import PopularDestinations from '@/components/Home/PopularDestinations.vue';
import Hotels from '@/components/Home/Hotels.vue';
import AlltripMobile from '@/components/Home/AlltripMobile.vue';
import OurAdvantage from '@/components/Home/OurAdvantage.vue';
import InsuranceSection from '@/components/Home/InsuranceSection.vue';
import istanbulImage from '@/assets/images/Istanbul.jpg';
import dubaiImage from '@/assets/images/Dubai.jpg';
import almatiImage from '@/assets/images/Almati.jpg';
import moskvaImage from '@/assets/images/Moskva.jpg';
import jiddaImage from '@/assets/images/Jidda.jpg';
import ToastUtil from '@/utils/toast';
import MobileModal from '@/components/Mobile/MobileModal.vue';

import newAirplane from '@/assets/icons/new-airplane.svg';
import bedImage from '@/assets/icons/bed.svg';
import cloudImage from '../assets/images/bulut.webp';

export default {
  name: 'Home',

  components: {
    MobileModal,
    Flights,
    TicketsSearchWidget,
    PopularDestinations,
    Hotels,
    OurAdvantage,
    InsuranceSection,
    AlltripMobile,
    NemoWidget,
  },

  data() {
    let cachedData = JSON.parse(localStorage.getItem('at_cached_data') || '{}');
    return {
      isMobileModal: false,
      windowScreen: window.innerWidth <= 640,
      activeTab: 'tickets',
      flights: [
        {
          id: 1,
          directions: [
            {
              flight: 'tas-ist-ta',
              date: new Date(),
              price: '7 162 275',
            },
            {
              flight: 'ist-tas-ta',
              date: new Date(),
              price: '4 907 714',
            },
          ],
          title: 'ist',
          image: istanbulImage,
        },
        {
          id: 2,
          directions: [
            {
              flight: 'tas-mow-ta',
              date: new Date(),
              price: '2 704 392',
            },
            {
              flight: 'mow-tas-ta',
              date: new Date(),
              price: '5 802 824',
            },
          ],
          title: 'mow',
          image: moskvaImage,
        },
        {
          id: 3,
          directions: [
            {
              flight: 'tas-dxb-ta',
              date: new Date(),
              price: '8 713 480',
            },
            {
              flight: 'dxb-tas-ta',
              date: new Date(),
              price: '4 929 847',
            },
          ],
          title: 'dxb',
          image: dubaiImage,
        },
        {
          id: 4,
          directions: [
            {
              flight: 'tas-ala-ta',
              date: new Date(),
              price: '1 764 119',
            },
            {
              flight: 'ala-tas-ta',
              date: new Date(),
              price: '4 018 636',
            },
          ],
          title: 'ala',
          image: almatiImage,
        },
        {
          id: 5,
          directions: [
            {
              flight: 'tas-cju-ta',
              date: new Date(),
              price: '9 836 961',
            },
            {
              flight: 'cju-tas-ta',
              date: new Date(),
              price: '6 069 932',
            },
          ],
          title: 'cju',
          image: jiddaImage,
        },
      ],
      deals: [
        {
          id: 1,
          title: 'dollar',
          icon: 'dollar',
        },
        {
          id: 2,
          title: 'callCenter',
          icon: 'callCenter',
        },
        {
          id: 3,
          title: 'client',
          icon: 'client',
        },
        {
          id: 4,
          title: 'cooperative',
          icon: 'cooperative',
        },
      ],
      newAirplane: newAirplane,
      bedImage: bedImage,
      cloudImage: cloudImage,
      currentLanguage: cachedData.currentLanguage || 'ru', // Default to 'en' if not set
      metaContent: {
        en: {
          title: 'AllTrip.Uz - UzAirWays air ticket office ticket Tashkent Uzbekistan',
          description: 'Optimal PRICES! 15 years on the market! Looking for cheap flights to Tashkent Uzbekistan, uz airways is your reliable partner in Uzbekistan - Samarkand, Nukus, Fergana, Urgench, Bukhara, Andijan, Karshi, Termez. We offer a wide range of flights from the best airlines at affordable prices.'
        },
        ru: {
          title: 'AllTrip.Uz - UzAirWays авиакасса билет Ташкент Узбекистан',
          description: 'Оптимальные ЦЕНЫ! 15 лет на рынке! Ищете дешевые авиабилеты в Ташкенте Узбекистан, uz airways - ваш надежный партнер по Узбекистану - Самарканд, Нукус, Фергана, Ургенч, Бухара, Андижан, Карши, Термез. Мы предлагаем широкий выбор рейсов от лучших авиакомпаний по доступным ценам.'
        },
        uz: {
          title: 'AllTrip.Uz - UzAirWays Aviakkasa Biletlari Toshkent O\'zbekiston',
          description: 'Bilet bozorida 10 yil tajriba! Arzonroq Avia Kassa Biletlari AllTrip.Uz hamrohingizan qulay narxlarda onlayn sotib oling Toshkent va O\'zbekistan barcha viloyatlari Samarqand, Nukus, Farg\'ona, Urganch, Buxoro, Andijon, Qarshi, Termizlardan Siz uchun 15 yildan beri xizmatizdamiz!'
        }
      }
    };
  },

  created() {
    const isModal = sessionStorage.getItem('mobileModal');
    if (isModal) {
      this.isMobileModal = JSON.parse(isModal);
    } else {
      const that = this;
      setTimeout(function () {
        that.isMobileModal = true;
      }, 20000);
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowScreen = window.innerWidth <= 640;
    };
    this.updateMetaInfo();
  },
  computed: {
    title() {
      return this.metaContent[this.currentLanguage].title;
    }
  },
  watch: {
    currentLanguage(newLang) {
      let cachedData = JSON.parse(localStorage.getItem('at_cached_data') || '{}');
      cachedData.currentLanguage = newLang;
      localStorage.setItem('at_cached_data', JSON.stringify(cachedData));
      this.updateMetaInfo();
    }
  },
  methods: {
    mobileModalEvent() {
      const that = this;
      setTimeout(function () {
        that.isMobileModal = false;
        sessionStorage.setItem('mobileModal', JSON.stringify(that.isMobileModal));
      }, 500);
    },
    switchTab(tabName) {
      this.activeTab = tabName;
    },
    getLocaleMsgPath(key) {
      return `pages.${this.$options.name}.${key}`;
    },
    getLocaleMsg(key) {
      return this.$getLocaleMsg(this.getLocaleMsgPath(key));
    },
    featureComingSoon() {
      ToastUtil.show({
        message: this.$t('common.Toast.soon'),
        type: 'success',
      });
    },
    updateMetaInfo() {
      this.$meta().refresh({
        title: this.metaContent[this.currentLanguage].title,
        meta: [
          {
            name: 'description',
            content: this.metaContent[this.currentLanguage].description,
          }
        ]
      });
    },
    setLanguage(lang) {
      this.currentLanguage = lang;
    }
  },
  metaInfo() {
    return {
      title: this.metaContent[this.currentLanguage].title,
      meta: [
        {
          name: 'description',
          content: this.metaContent[this.currentLanguage].description,
        }
      ]
    };
  }
};
</script>

<style>
.slide-bottom-enter-active {
  transition: transform 0.5s ease;
}

.slide-bottom-leave-active {
  transition: transform 0.5s ease;
}

.slide-bottom-enter {
  transform: translateY(100%);
}

.slide-bottom-leave-to {
  transform: translateY(100%);
}
</style>
