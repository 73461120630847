var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Transition",
        {
          attrs: {
            "enter-active-class": "slide-bottom-enter-active",
            "leave-active-class": "slide-bottom-leave-active",
            "enter-class": "slide-bottom-enter",
            "leave-to-class": "slide-bottom-leave-to",
          },
        },
        [
          _vm.isMobileModal && _vm.windowScreen
            ? _c("MobileModal", {
                staticClass: "sm:hidden",
                on: { closeEvent: _vm.mobileModalEvent },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section section_home_tickets-search-widget pb-10" },
        [
          _c(
            "div",
            {
              staticClass:
                "section__container grid-container mx-auto mt-[24px] w-full md:mt-[36px] lg:mt-[43px] lg:max-w-7xl",
            },
            [
              _c(
                "h1",
                {
                  staticClass:
                    "text-center text-2xl font-bold text-white max-sm:text-xl",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.getLocaleMsg("we_show")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-4 flex justify-center" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-center text-xl font-medium text-white max-sm:max-w-[230px] max-sm:text-xs",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getLocaleMsg("ideal")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-8 flex justify-center space-x-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header__tour-btn flex cursor-pointer items-center space-x-2 rounded-xl bg-white px-4 py-2 text-sm font-bold text-[#36454F]",
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.newAirplane,
                            expression: "newAirplane",
                          },
                        ],
                        attrs: { alt: "" },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.getLocaleMsg("flight")))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "header__tour-btn flex cursor-pointer items-center space-x-2 rounded-xl bg-white/[8%] px-4 py-2 text-sm font-bold text-white",
                      on: { click: _vm.featureComingSoon },
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.bedImage,
                            expression: "bedImage",
                          },
                        ],
                        attrs: { alt: "" },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.getLocaleMsg("hotel")))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "header__tour-btn flex cursor-pointer items-center space-x-2 rounded-xl bg-white/[8%] px-4 py-2 text-sm font-bold text-white",
                      attrs: { to: { name: "insurance" } },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            width: "24",
                            height: "24",
                            viewBox: "0 0 24 24",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: { "clip-path": "url(#uicons-nr8aa9peeb)" },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M10.5 13H8V10H10.5V7.5H13.5V10H16V13H13.5V15.5H10.5V13ZM12 2L4 5V11.09C4 16.14 7.41 20.85 12 22C16.59 20.85 20 16.14 20 11.09V5L12 2Z",
                                  fill: "currentColor",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("defs", [
                            _c(
                              "clipPath",
                              { attrs: { id: "uicons-nr8aa9peeb" } },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    fill: "currentColor",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Insurance")]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "relative" },
                [
                  _c("TicketsSearchWidget", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "tickets",
                        expression: "activeTab === 'tickets'",
                      },
                    ],
                  }),
                  _vm._v(" "),
                  _c("NemoWidget", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "charters",
                        expression: "activeTab === 'charters'",
                      },
                    ],
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "absolute bottom-0 right-[3%]" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.cloudImage,
                  expression: "cloudImage",
                },
              ],
              attrs: { alt: "Cloud image" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "section destinations" }, [
        _c(
          "div",
          { staticClass: "section__container grid-container" },
          [_c("PopularDestinations")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section flights" }, [
        _c(
          "div",
          { staticClass: "section__container grid-container" },
          [_c("Flights", { attrs: { flights: _vm.flights } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c("InsuranceSection"),
      _vm._v(" "),
      _c("div", { staticClass: "section our-advantage" }, [
        _c(
          "div",
          { staticClass: "section__container grid-container" },
          [_c("OurAdvantage")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mobile__alltrip" }, [_c("AlltripMobile")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }